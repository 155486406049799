import { MainContainer } from "../utils/Maincontainer";

export default function Contact() {
    return (
        <MainContainer>
            <div className="flex flex-col md:flex-row justify-between md:px-24 px-10 pt-10 md:pt-0 items-center min-h-screen text-white bg-gradient-to-b from-gray-800 to-orange-100 border-b-2">
                <div className="flex flex-col justify-center items-start gap-2 h-full">
                    <div className="text-4xl font-bold pb-10">Reach Us!</div>
                    <div className="md:w-1/2">
                        We’d love to get connected with you and see how we can help you with your needs! Reach out to us via email or phone call below.
                    </div>
                    <div className=" py-10 w-1/2 border-b-2" />
                    <div className="pt-10 flex flex-col gap-4">
                        <div>
                            Bainbridge Island, Washington
                        </div>
                        <div className="font-bold text-orange-300">
                            206-255-0746
                        </div>
                        <div className="font-bold text-orange-300">
                            islandhands206@gmail.com
                        </div>
                    </div>
                </div>
                <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8 mt-10 md:mt-0 mb-10">

                    <div className="text-center mb-6">
                        <h1 className="text-3xl font-semibold text-purple-800">Contact Us</h1>
                        <p className="text-gray-500 mt-2">
                            Questions, feedback, or get a quote — we're here for it all.
                        </p>
                    </div>

                    <form className="space-y-6">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">First Name</label>
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-purple-800 focus:border-purple-800"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-purple-800 focus:border-purple-800"
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                placeholder="Email"
                                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-purple-800 focus:border-purple-800"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Subject</label>
                            <input
                                type="text"
                                placeholder="Subject"
                                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-purple-800 focus:border-purple-800"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Message</label>
                            <textarea
                                placeholder="Your Message"
                                rows="4"
                                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-purple-800 focus:border-purple-800"
                                required
                            ></textarea>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="inline-block w-full py-3 px-6 bg-yellow-600 text-white font-semibold rounded-md shadow-sm hover:bg-yellow-700"
                            >
                                Get Connected!
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </MainContainer>
    )
}