import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';

const GoToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Fonction pour scroller jusqu'en haut
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling
        });
    };

    // Détecter si l'utilisateur a scrollé
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        // Nettoyer l'event listener à la fin
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <div>
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-5 right-5 p-3 rounded-full bg-orange-700 text-white text-lg hover:bg-orange-900 transition duration-300 z-20"
                    aria-label="Go to top"
                >
                    <FaArrowCircleUp />
                </button>
            )}
        </div>
    );
};

export default GoToTopButton;
