import { useNavigate } from "react-router-dom";


const Footer = () => {
    const navigate = useNavigate()

    return (
        <div className='  text-black text-lg px-10 bg-orange-300 pt-16 pb-24 '>
            <div className='flex sm:flex-row flex-col'>
                <div className="flex-grow lg:w-1/3 sm:w-full pr-24">
                    <div className='text-white font-bold text-2xl'>Island Hand</div>
                    <div className='mt-5 '>
                        We specialize in commercial and residential deep cleaning and janitorial services. This includes general cleaning, carpet cleaning, janitorial services & more! We have a special process to clean bathrooms that truly sanitizes.
                    </div>

                    <div className='mt-5 flex flex-col'>
                        <span>Locations :</span>
                        <span>
                            Bainbridge Islands
                        </span>
                    </div>

                    <div className='mt-3 flex justify-between  xl:flex-row flex-col'>
                        <span> Email </span>
                        <span className='text-blue-400'>: Admin@islandhands.com</span>
                    </div>

                    <div className='mt-2 flex flex-col '>
                        <div className='flex justify-between  xl:flex-row flex-col'> <span>Phone number</span> <span className='text-blue-400'><a href="tel:+12062550746" className='hover:text-green-500'>: +1 206-255-0746</a></span></div>
                    </div>
                </div>

                <div className="flex-grow lg:w-1/3 sm:w-full">
                    <div className='text-white font-bold text-2xl'>Quick Links</div>
                    <div className='flex flex-col gap-12 mt-5 font-bold text-2xl'>
                        <span onClick={() => navigate('/')} className='cursor-pointer hover:underline hover:text-blue-400'>Home</span>

                        <span onClick={() => navigate('/services')} className='cursor-pointer hover:underline hover:text-blue-400'>Services</span>

                        <span onClick={() => navigate('/about')} className='cursor-pointer hover:underline hover:text-blue-400'>About Us</span>

                        <span onClick={() => navigate('/contact')} className='cursor-pointer hover:underline hover:text-blue-400'>Contact</span>
                    </div>
                </div>

                <div className="flex-grow lg:w-1/3 sm:w-full">
                    <div className='text-white font-bold text-2xl'>Contact Us</div>
                    {/* Ajoutez ici votre formulaire d'abonnement à la newsletter */}
                    <div className="mt-5">
                        <input className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 text-sm sm:text-2xl sm:leading-6" id="First Name" type="text" placeholder='Name' />
                    </div>
                    <div className="mt-5">
                        <input className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 text-sm sm:text-2xl sm:leading-6" id="Last name" type="text" placeholder='Email' />
                    </div>
                    <div className="mt-5">
                        <div className="col-span-full">
                            <div className="mt-2">
                                <textarea
                                    placeholder={'Message'}
                                    id="about"
                                    name="about"
                                    rows={7}
                                    onChange={(e) => { }}
                                    className="block w-full rounded-md border-0 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 text-sm sm:text-2xl sm:leading-6"
                                />
                            </div>

                        </div>
                    </div>
                    <div className='flex items-center w-full mt-3'>
                        <button className="text-red hover:before:bg-redborder-orange-400 relative h-[50px] w-full overflow-hidden border border-orange-400 bg-white px-3 text-orange-400 shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-orange-400 before:transition-all before:duration-500 hover:text-white hover:shadow-orange-400 hover:before:left-0 hover:before:w-full">
                            <span className="relative z-10">Send</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className='mt-10 flex flex-col items-center justify-center'>
                <span>© Island Hands LLC 2021 </span>
                <div className='flex items-center sm:flex-row flex-col  gap-2'>
                    <div>Designed & built by</div>
                    <div >
                        <a href='https://cd.linkedin.com/in/shams-kibonge-5623a7260' target='_blank' rel='noreferrer' className='flex items-center gap-2 cursor-pointer'>
                            <span className='text-blue-500 underline'>Shams Kibonge.</span>
                            {/* <span><FaLinkedin size={20} className='text-[#0068C2]' /></span> */}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;