import { MainContainer } from "../utils/Maincontainer";

import I1 from '../assets/img/1.jpg'
import I5 from '../assets/img/b2.jpg';
import I2 from '../assets/img/b1.jpg';
import I3 from '../assets/img/b3.jpg'
import I4 from '../assets/img/b4.jpg';
import I6 from '../assets/img/b5.jpg';
import I7 from '../assets/img/bg6.jpeg';
import I8 from '../assets/img/b7.jpg';

export default function Services() {

    const services = [
        {
            title: 'Commercial & Residential Cleaning',
            desc1: 'General Cleaning Daily, Weekly, Monthly',
            desc2: 'Flexible Hours',
            image: I1
        },
        {
            title: 'Office & Manufacturing Workspace Cleaning',
            desc1: 'Don’t let your workspace get out of control. Wa can help you get it clean so you can be more productive.',
            image: I6
        },
        {
            title: 'Floor Finish Maintenance',
            desc1: 'Monthly/Quarterly',
            desc2: 'Includes floor cleaning, waxing, & buffing.',
            image: I5
        },
        {
            title: 'Window Cleaning',
            desc1: 'We clean windows so that you don’t have to!',
            image: I7
        },
        {
            title: 'Move-Out Cleanup',
            desc1: 'Too much hassle with the move? Let us take a load off.',
            image: I8
        },
        {
            title: 'Vacation Rental Maintenance',
            desc1: 'Daily, Monthly, & Weekly',
            image: I4
        },
        {
            title: 'Carpet Cleaning',
            desc1: 'Residential & Commercial',
            desc2: 'Bonnet, Encapsulation & Extraction Services',
            image: I2
        },
        {
            title: 'Disinfection Services',
            desc1: 'Commercial & Residential',
            desc2: 'We will disinfect your space so germs can’t spread any further!',
            image: I4
        },
        {
            title: 'Janitorial Services',
            desc1: 'Commercial',
            desc2: 'Professional janitorial services for every need.',
            image: I3
        }
    ];

    return (
        <MainContainer>
            <div className="relative bg-cover bg-center h-full " style={{ backgroundImage: `url(${I1})` }}>
                <div className="absolute inset-0 bg-black bg-opacity-70"></div> {/* Overlay */}
                <div className="relative z-10 flex flex-col items-center px-8 text-white  mx-auto">
                    <h1 className="text-4xl md:text-6xl font-bold mb-6">Services</h1>
                    <div className="container mx-auto px-6 mt-10">
                        <div className="flex flex-wrap justify-center overflow-auto w-full gap-5 mb-10">
                            {services.map((service, index) => (
                                <div key={index} className="w-[400px] min-w-[300px] cursor-pointer">
                                    <div className="relative">
                                        <img src={service.image} alt={service.title} className="w-full h-40 rounded-t-md object-cover hover:animate-pulse" />
                                    </div>
                                    <div className="flex flex-col gap-2 bg-gray-400 h-32 rounded-b-md w-full text-center">
                                        <div className=" text-xl text-black font-bold">{service.title}</div>
                                        <div>{service.desc1}</div>
                                        <div>{service.desc2}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}