// Experience.js
import React from 'react';

const Review = () => {
    return (

        <section className="pb-16 bg-gray-100 h-full flex flex-col justify-center">
            <div className="mt-5 container mx-auto px-4 items-center">
                <div className="w-full flex flex-col md:text-4xl text-lg text-center h-full">
                    Island Hands is amazing! They helped me move and I just found out they also clean the Bainbridge and Poulsbo libraries. Very honest, hard working and flexible. They are bonded and insured as well.
                </div>
                <div className='w-full text-center mt-5'>
                    — Twin Thompson
                </div>
            </div>
        </section>
    );
};

export default Review;
