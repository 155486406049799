// Experience.js
import React from 'react';

import I1 from '../assets/img/1.jpg'

const Sec4 = ({ navigate }) => {
    return (

        <section className="relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${I1})` }}>
            <div className="absolute inset-0 bg-black bg-opacity-70"></div> {/* Overlay */}
            <div className="relative z-10 flex flex-col items-start justify-center h-full px-8 text-white max-w-4xl mx-auto">
                <h1 className="text-4xl md:text-6xl font-bold mb-6">More Than Just Product and Hard Work</h1>
                <p className="text-lg md:text-xl leading-relaxed mb-6">
                    We specialize in commercial and residential deep cleaning and janitorial services.
                    This includes general cleaning, carpet cleaning, janitorial services & more! We have
                    a special process to clean bathrooms that truly sanitizes. This is more than just product
                    and hard work; we have commercial grade disinfectant and equipment that raises the
                    temperature to 200 degrees. If germs or viruses have been in your house or office, we
                    can sanitize them to keep them from spreading. We're certified to kill germs and stop
                    them from spreading through Goodwill. We are hard working, licensed, bonded and insured,
                    and our hours are very flexible.
                </p>
                <div onClick={() => { navigate('/contact') }} className="cursor-pointer bg-black text-white px-6 py-3 font-semibold rounded hover:bg-gray-700 transition">
                    Get Started
                </div>
            </div>
        </section>
    );
};

export default Sec4;
