// Experience.js
import React from 'react';

import I1 from '../assets/img/1.jpg'
import I2 from '../assets/img/bg.jpg'
import I3 from '../assets/img/bg1.jpeg'
import I4 from '../assets/img/bgs.jpg'

const Experience = () => {
    return (
        <section className="pb-16 bg-white">
            <div className='flex items-center justify-center w-full md:text-6xl text-4xl font-bold bg-orange-300 p-5'>
                Commercial & Residential Services
            </div>
            <div className="mt-5 container mx-auto px-4 flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2 grid grid-cols-2 gap-4">
                    <img src={I1} alt="Experience" className="w-full h-auto object-cover rounded-md shadow-md" />
                    <img src={I2} alt="Experience" className="w-full h-auto object-cover rounded-md shadow-md mt-8" />
                    <img src={I3} alt="Experience" className="w-full h-auto object-cover rounded-md shadow-md mt-8" />
                    <img src={I4} alt="Experience" className="w-full h-auto object-cover rounded-md shadow-md" />
                </div>
                <div className="w-full md:w-1/2 mt-8 md:mt-0 md:pl-12">
                    <h2 className="text-4xl font-bold">Our Experience</h2>
                    <p className="mt-4 text-gray-600">
                        With years of experience, we provide top-notch cleaning services for residential and commercial spaces.
                    </p>
                    <ul className="mt-4 space-y-4">
                        <li>✅ Happy customers.</li>
                        <li>✅ Trained professionals at your service.</li>
                        <li>✅ Available in multiple locations in the Island.</li>
                    </ul>
                    {/* <button className="mt-8 bg-green-500 hover:bg-green-600 px-6 py-3 rounded-md text-white">Read More</button> */}
                </div>
            </div>
        </section>
    );
};

export default Experience;
