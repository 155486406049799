import { useNavigate } from "react-router-dom";
import Experience from "../components/Experience";
import Features from "../components/Features";
import Hero from "../components/Hero";
import Meet from "../components/Meet";
import Review from "../components/Review";
import Sec4 from "../components/Sec4";
import ServicesSection from "../components/services";
import { MainContainer } from "../utils/Maincontainer";

export default function Home() {
    const navigate = useNavigate()
    return (
        <MainContainer>
            <Hero navigate={navigate} />
            <Features />
            <Experience />
            <Meet navigate={navigate} />
            <Sec4 navigate={navigate} />
            <ServicesSection navigate={navigate} />
            <Review />
        </MainContainer>
    )
}