// Experience.js
import React from 'react';

import I1 from '../assets/img/kims.jpg'

const Meet = ({ navigate }) => {

    return (

        <section className="pb-16 bg-gray-100">
            <div className="mt-5 container mx-auto px-4 flex flex-col md:flex-row items-center">
                <div className="w-full flex flex-col">
                    <div className='flex flex-col text-5xl font-bold'>
                        <span>Meet</span>
                        <span>Kims</span>
                    </div>
                    <div className="flex lg:hidden w-1/2   ">
                        <img src={I1} alt="Experience" className=" object-cover rounded-full shadow-md mt-10" />
                    </div>
                    <div className='w-full font-light mt-5'>
                        <span className='w-80 text-lg'>
                            All the way from The Democratic Republic of Congo, Kims strives every day to make his community a better place by providing full service cleaning & facility maintenance to the greater Kitsap area and beyond. He lives near Poulsbo with his wife & has 7 grown children. Kims runs his team and is actively involved in his business and community.
                        </span>
                    </div>
                    <div onClick={() => { navigate("/about") }} className='mt-10 cursor-pointer bg-orange-500 hover:bg-orange-600 px-6 py-3 rounded-md w-fit text-white'>
                        LEARN MORE
                    </div>
                </div>
                <div className="hidden lg:flex md:w-1/2  ">
                    <img src={I1} alt="Experience" className=" object-cover rounded-full shadow-md mt-10" />
                </div>
            </div>
        </section>
    );
};

export default Meet;
