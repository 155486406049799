// Header.js
import React, { useRef, useState } from 'react';
import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import { useOutsideFinder } from '../utils/useOutsideFinder';
import { HiMenu } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const Header = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const divRef = useRef()


    useOutsideFinder(() => setShow(false), divRef)
    return (
        <header className="bg-orange-400 text-white">
            <div className="flex justify-between items-center py-2 md:px-24 px-10">
                <div>
                    <img src={logo} alt={'logo'} className="w-20 sm:w-full h-10 sm:h-24" />
                </div>
                <div className="hidden md:flex items-center space-x-4">
                    <div onClick={() => { navigate('/') }} className="cursor-pointer hover:text-orange-100 hover:underline">Home</div>
                    <div onClick={() => { navigate('/services') }} className="cursor-pointer hover:text-orange-100 hover:underline">Services</div>
                    <div onClick={() => { navigate('/about') }} className="cursor-pointer hover:text-orange-100 hover:underline">About Us</div>
                    <div onClick={() => { navigate('/contact') }} className="cursor-pointer hover:text-orange-100 hover:underline">Contact</div>
                    <div onClick={() => { navigate('/contact') }} className='cursor-pointer bg-orange-500 hover:bg-orange-600 px-6 py-3 rounded-md w-fit text-white'>Get a Quote</div>
                </div>
                <div className='flex md:hidden hover:text-orange-200 cursor-pointer' >
                    {!show ?
                        <HiMenu size={25} onClick={() => { setShow(true) }} />
                        :
                        <MdClose size={25} onClick={() => { setShow(false) }} />
                    }
                </div>
            </div>
            <div ref={divRef} className={`min-w-full md:hidden cursor-pointer ${show ? 'flex animate-slide-in' : 'hidden '} bg-orange-200 shadow-xl z-50 fixed flex-col `}>
                <div className={`flex items-center justify-between border-b border-orange-300 hover:bg-orange-300 p-3 `} onClick={() => { setShow(false); navigate('/') }}>
                    <label className='cursor-pointer'>{'Home'}</label>
                    <FaArrowRight size={15} />
                </div>
                <div className={`flex items-center justify-between border-b border-orange-300 hover:bg-orange-300 p-3  `} onClick={() => { setShow(false); navigate('/services') }}>
                    <label className='cursor-pointer'>
                        {'Services'}
                    </label>
                    <FaArrowRight size={15} />
                </div>
                <div className={`flex items-center justify-between border-b border-orange-300 hover:bg-orange-300 p-3`} onClick={() => { setShow(false); navigate('/about') }}>
                    <label className='cursor-pointer'>{'About'}</label>
                    <FaArrowRight size={15} />
                </div>
                <div className={`flex items-center justify-between border-b border-orange-300 hover:bg-orange-300 p-3 `} onClick={() => { setShow(false); navigate('/contact') }}>
                    <label className='cursor-pointer'>{'Contact'}</label>
                    <FaArrowRight size={15} />
                </div>
                <div className={`flex items-center justify-between border-b border-orange-300 hover:bg-orange-300 p-3 `} onClick={() => { setShow(false); navigate('/contact') }}>
                    <label className='cursor-pointer'>{'Get a quote'}</label>
                    <FaArrowRight size={15} />
                </div>
            </div>
            <div className="bg-orange-300 text-sm py-2 px-10">
                <div className="container mx-auto flex justify-end space-x-6">
                    <p><a href="tel:+12062550746" className='hover:text-green-500'>📞 +1 206-255-0746</a></p>
                    <p>📍 Bainbridge Island</p>
                </div>
            </div>
        </header>
    );
};

export default Header;
