// App.js
import React from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import ScrollToTop from './utils/ScrollTop';
import GoToTopButton from './utils/top';

function App() {
    return (
        <Router>
            {/* <DataProvider> */}
            <ScrollToTop />
            <Routes>
                {/* <Route exact path="/Login" element={<Login />} /> */}

                <Route exact path="/" element={<Home />} />
                <Route exact path="/Home" element={<Home />} />
                <Route exact path="/Services" element={<Services />} />
                <Route exact path="/About" element={<About />} />
                <Route exact path="/Contact" element={<Contact />} />
            </Routes>
            <GoToTopButton />
            {/* </DataProvider> */}
        </Router>
    );
}

export default App;
