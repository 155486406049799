import Footer from "../components/Footer"
import Header from "../components/header"


export const MainContainer = ({ children, page, spage }) => {

    return (
        <div>

            <Header />
            <div>
                {children}
            </div>
            <Footer />
        </div>
    )
}