// Features.js
import React from 'react';

const features = [
    // { title: 'Comfort', description: 'We provide comfort at its best.', icon: '🛋️' },
    { title: 'Cleaning', description: 'Exceptional cleaning services.', icon: '🧹' },
    // { title: 'Customer Support', description: '24/7 customer support available.', icon: '☎️' },
    // { title: 'Efficiency', description: 'Quick and reliable services.', icon: '⚡' },
    { title: 'Eco-Friendly', description: 'We use eco-friendly products.', icon: '🌍' },
    { title: 'Affordability', description: 'Services that fit your budget.', icon: '💸' }
];

const Features = () => {
    return (
        <section id='more' className="py-12 bg-gray-100">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-center">Our Features</h2>
                <div className="mt-8 grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {features.map((feature, index) => (
                        <div key={index} className="bg-white shadow-md rounded-lg p-6 text-center">
                            <div className="text-4xl mb-4">{feature.icon}</div>
                            <h3 className="text-2xl font-semibold">{feature.title}</h3>
                            <p className="mt-2 text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;
