// ServicesSection.jsx
import React from 'react';
import I1 from '../assets/img/b2.jpg';
import I2 from '../assets/img/b1.jpg';
import I3 from '../assets/img/b3.jpg'
import I4 from '../assets/img/b4.jpg'; // Ajoutez toutes vos images ici

const services = [
    {
        title: 'Floor Cleaning & Waxing',
        description: 'Floor Cleaning, Waxing & Buffing done right',
        image: I1
    },
    {
        title: 'Carpet Cleaning & Waxing',
        description: 'Bonnet & Encapsulation Deep cleaning Extraction',
        image: I2
    },
    {
        title: 'Janitorial Services',
        description: 'We do the hard stuff, so you don’t have to',
        image: I3
    },
    {
        title: 'Disinfection Services',
        description: 'We will disinfect your home, office, business so that you can enjoy a clean environment',
        image: I4
    }
];

const ServicesSection = ({ navigate }) => {
    return (
        <section className="bg-gray-200 py-16">
            <div className='flex items-center justify-center w-full md:text-6xl text-4xl font-bold bg-orange-300 p-5'>
                Services
            </div>
            <div className="container mx-auto px-6 mt-10">

                <div className="flex flex-col md:flex-row md:space-x-6">
                    {services.map((service, index) => (
                        <div key={index} className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden w-full md:w-1/4 mb-6">
                            <div className="relative">
                                <img src={service.image} alt={service.title} className="w-full h-48 object-cover" />
                                {/* <div className="absolute top-0 right-0 bg-yellow-500 text-black p-2 rounded-full m-4 cursor-pointer">
                                    <span>&rarr;</span> Flèche de navigation
                                </div> */}
                            </div>
                            <div className="p-6">
                                <h3 className="text-2xl font-semibold text-gray-800">{service.title}</h3>
                                <p className="text-gray-600 mt-4">{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Bouton pour voir plus de services */}
                <div className="mt-8 w-full flex justify-center">
                    <div
                        onClick={() => { navigate('/services') }}
                        className="w-fit cursor-pointer bg-yellow-500 text-black font-semibold px-8 py-4 rounded hover:bg-yellow-600 text-center">
                        See Our Full List of Services
                    </div>
                </div>

            </div>
        </section>
    );
};

export default ServicesSection;
