import { MainContainer } from "../utils/Maincontainer";
import I1 from '../assets/img/kims.jpg'
import A1 from '../assets/img/a1.jpg'
import A2 from '../assets/img/a2.jpg'

export default function About() {
    return (
        <MainContainer>
            <div className="relative bg-cover bg-center md:px-24 px-10 pt-10">
                <div className="flex lg:flex-row flex-col gap-10">
                    <div className="w-full md:w-1/2  md:mt-0 md:pl-12 block lg:hidden">
                        <img src={I1} alt="Experience" className=" object-cover rounded-full shadow-md mt-10" />
                    </div>
                    <div className="flex flex-col lg:text-2xl md:text-lg text-sm font-light gap-3 lg:w-1/2 mb-10">
                        <span className="font-bold text-4xl">About</span>
                        <span>
                            In the DRC, Kims Kingombe owned a trucking company and transported fuel. Threats to the region and its rich resources were constant.
                        </span>
                        <span>
                            Kims said “We didn’t have a stable government. We didn’t have security as Congolese people. You could lose your business in one day.” Indeed, rebels targeted his family and took his truck to strike another village.
                        </span>
                        <span>
                            Kingombe sought safety in America.
                        </span>
                        <span>
                            Granted political asylum in 2005, he initially had to come alone. Kingombe made his way to a shared apartment in an immigrant community near Federal Way, learned English his sixth language and began working to reunite his family.
                        </span>
                        <span>
                            He opened Kims Cleaning. By day he fueled airplanes, by night he cleaned offices. “Kims used to send money home for the kids’ school, for food everything we had was from him,” his wife said. When the remaining visas were finally approved in 2012, Kingombe had just three weeks to sell his cleaning equipment to afford the family’s airfare.
                        </span>
                        <span>
                            Fast-forward to 2015 when Kims opened “Island Hands” and to this day is running it full-time providing for his family & making an impact on his community in Poulsbo, WA.
                        </span>
                        <div className="flex md:flex-row flex-col gap-1">
                            <img src={A1} alt="Experience" className=" md:w-1/2 hover:animate-pulse cursor-pointer" />
                            <img src={A2} alt="Experience" className=" md:w-1/2 hover:animate-pulse cursor-pointer" />

                        </div>
                    </div>
                    <div className="w-full md:w-1/2  md:mt-0 md:pl-12 hidden lg:block">
                        <img src={I1} alt="Experience" className=" object-cover rounded-full shadow-md mt-10" />
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}