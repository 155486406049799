// Hero.js
import React from 'react';
import Bgs from '../assets/img/bg1.jpeg'
import Bg from '../assets/img/bg.jpg'

const Hero = ({ navigate }) => {
    return (
        <div className="relative h-screen w-full " style={{ backgroundImage: "url('../assets/img/bgs.jpg')" }}>
            <img src={Bgs} alt="Building" className='absolute w-full h-full object-fill blur-sm' />
            <div className="ld:px-24 px-10 w-full h-full flex items-center justify-center">
                <div className='relative z-10 flex md:flex-row flex-col items-center justify-center w-[1500px] h-full bg-transparent text-white text-center'>
                    <img src={Bg} alt="Building" className='lg:w-[600px] w-[400px] rounded-xl' />
                    <div className='relative z-10 flex flex-col items-center justify-center  text-black text-center'>
                        <h1 className="lg:text-5xl text-2xl font-bold">Island Hands is a full service cleaning and maintenance company.</h1>
                        <p className="mt-4 lg:text-lg text-md">Licensed, bonded, & insured to provide you with the best service!.</p>
                        <div className="mt-8 space-x-4 text-white">
                            <button onClick={() => { navigate("/contact") }} className="bg-orange-500 hover:bg-orange-600 px-6 py-3 rounded-md">Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;